// Individual bootstrap dependencies to minimise size of output
import "popper.js";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/tooltip";
import "bootstrap-select";

import "../sass/project.scss";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
import "./theme";
import "./pricing";
import "notyf/notyf.min.css";
import "./jQuerySerializeObject.js";
import "whatwg-fetch"; // Polyfill for Request
import Navbar from "/guidebook/components/navbar/Navbar.svelte";
import MobileNavbar from "/guidebook/components/navbar/MobileNavbar.svelte";
import { initComponentById, initPage } from "/shared/svelte";
import("./clipboard.js");

$(async () => {
  // load navbar components for every page in traveller app
  initComponentById("navbar", Navbar);
  initComponentById("mobileNav", MobileNavbar);

  import("./messages.js");

  if (window.app == "traveller") {
    import("./clipboard.js");
    if (window.page == "/traveller/wishlist/") {
      initPage(await import("/routes/traveller/wishlist.svelte"));
    } else if (window.page == "/traveller/vouchers/") {
      initPage(await import("/routes/traveller/vouchers.svelte"));
    } else if (window.page == "/traveller/suggestions/") {
      initPage(await import("/routes/traveller/suggestions.svelte"));
    } else {
      import("./traveller/components/shareModal/shareModal.js");
      import("./traveller/components/CTAChecklist.js");
    }
    import("./traveller/components/inviteFriendBanner.js");
  } else if (window.app == "guidebook") {
    import("./guidebook/entrypoint.js");
  }

  if (window.page == "businessProfile") {
    import("./business/profile/profile.js");
    import("/business/enquiry/bookingEnquiry.js");
    import("/business/profile/guestQuestion/guestQuestion.js");
  } else if (window.page == "hub") {
    import("./hub.js");
  } else if (window.page == "basicProfileSignUp") {
    import("./signup/basicProfile.js");
    import("./openingClosingTimesWidget");
  } else if (window.page == "locationSignUp") {
    import("../css/google_maps_widget.css");
    import("./signup/location.js");
    import("./signup/covidFields.js");
  } else if (window.page == "extendedProfile") {
    import("./signup/extendedProfile.js");
    import("./editTeam.js");
  } else if (window.page == "recommendations") {
    import("./recommendations.js");
  } else if (window.page == "dashboard") {
    import("./dashboard.js");
  } else if (window.page == "guest_recommendations") {
    import("./business/recommendations.js");
  } else if (window.page == "editBasicInfo") {
    import("./signup/basicProfile.js");
    import("./signup/covidFields.js");
    import("../css/google_maps_widget.css");
    import("./openingClosingTimesWidget");
  } else if (window.page == "editGuestHub") {
    import("./editGuestHub.js");
    import("./editTeam.js");
    import("../css/google_maps_widget.css");
  } else if (window.page == "addLocalService") {
    import("../css/google_maps_widget.css");
  } else if (window.page == "editInventory") {
    import("./editInventory.js");
  } else if (window.page == "businessUpdateCreate") {
    import("./blog/businessBlog.js");
  } else if (window.page == "createEvent") {
    import("./createEvent.js");
    import("./eventsCalendar.js");
  } else if (window.page == "join-areas") {
    import("./areas/join.js");
  } else if (window.page == "editEatDrinkItems") {
    import("./editEatDrinkItems.js");
  } else if (window.page == "referrals") {
    import("./referrals.js");
  } else if (window.page == "staffDiscounts") {
    import("./editTeam.js");
    import("./staffDiscounts.js");
  } else if (window.page == "travellerDashboard") {
    import("./traveller/dashboard.js");
  } else if (window.page == "travellerDashboardPublic") {
    import("./traveller/dashboard.js");
  } else if (window.page == "travellerNetworkCountry") {
    import("./traveller/network/country.js");
  } else if (window.page == "adminPreviewTheGuide") {
    import("./adminPreviewTheGuide.js");
  } else if (window.page == "region") {
    import("./traveller/region.js");
  } else if (window.page == "highlights") {
    import("./traveller/highlights.js");
  } else if (window.page == "theirHighlights") {
    import("./traveller/theirHighlights.js");
  } else if (window.page == "walkthrough_country") {
    import("./traveller/signup/walkthrough/walkthrough.js");
  } else if (window.page == "following") {
    import("./traveller/following.js");
  } else if (window.page == "wishlistEdit") {
    import("./traveller/wishlist/wishlistEdit.js");
  } else if (window.page == "travellerCoupon") {
    import("./traveller/coupon.js");
  } else if (window.page == "businessCoupon") {
    import("./business/coupon.js");
  } else if (window.page == "business_referral") {
    import("./traveller/signup/highlights/highlights.js");
  } else if (window.page == "travellerSignupTravellerReferral") {
    import("./traveller/signup/traveller/referral.js");
  } else if (window.page == "travellerSignupCold") {
    import("./traveller/signup/cold/cold.js");
  } else if (window.page == "travellerLogin") {
    import("./traveller/login.js");
  } else if (window.page == "hashtags") {
    import("./traveller/signup/hashtags/hashtags.js");
  } else if (window.page == "checklist") {
    import("./traveller/checklist/checklist.js");
  } else if (window.page == "recommend_business_multiple") {
    import("./traveller/signup/business/recommendMultiple.js");
  } else if (window.page == "recommend_business_single") {
    import("./traveller/signup/business/recommendSingle.js");
  } else if (window.page == "wishlistCountry") {
    import("./traveller/signup/wishlist/wishlist.js");
  } else if (window.page == "travellerUpdate") {
    import("./traveller/update/travellerUpdate.js");
  } else if (window.page == "guidebookError") {
    import("./guidebook/errors/errors.js");
  } else if (window.page == "countries") {
    import("./guidebook/countries.js");
  } else if (window.page == "checkout/productDetail") {
    import("./checkout/productDetail.js");
  } else if (window.page == "checkout/productGallery") {
    import("./checkout/productGallery.js");
  } else if (window.page == "checkout/reservationDetail") {
    import("./checkout/reservationDetail.js");
  } else if (window.page == "checkout/settings") {
    import("./checkout/settingsPage.js");
  }
  // initialize bootstrap select pickers throughout

  if ($(".select").length) {
    $(".select").selectpicker();
  }
  if ($(".lazyselect").length) {
    $(".lazyselect").selectpicker();
  }
});
