const subscribeToBasic = () => {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement("form");
  form.method = "POST";
  form.action = window.subscribeToBasicUrl;
  const hiddenField = document.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = "csrfmiddlewaretoken";
  hiddenField.value = window.csrfToken;
  form.appendChild(hiddenField);

  document.body.appendChild(form);
  form.submit();
};

const startSubscriptionFlow = (selectedPlan) => {
  if (selectedPlan === "") {
    subscribeToBasic();
    return;
  }

  const data = {
    signed_business_id: window.signedBusinessId,
    cancel_url: window.location.toString(),
    price: selectedPlan,
    success_url: window.successUrl,
  };
  $.post(window.createCheckoutSessionUrl, data).then((data) => {
    stripe.redirectToCheckout({ sessionId: data.session_id }).then((result) => {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
  });
};

if (window.currentPage === "pricing") {
  $(document).ready(() => {
    // Handler for all the "real" plan buttons that actually result in money.
    $(".plan-button").click((ev) => {
      ev.preventDefault();
      const el = $(ev.currentTarget);
      const selectedPlan = el.attr("data-plan");
      el.html('<div class="spinner-border" role="status">');
      startSubscriptionFlow(selectedPlan);
    });
  });
}
