/**
 * Directory – Directory & Listing Bootstrap 4 Theme v. 1.4.2
 * Homepage: https://themes.getbootstrap.com/product/directory-directory-listing-bootstrap-4-theme/
 * Copyright 2020, Bootstrapious - https://bootstrapious.com
 */

"use strict";

import "magnific-popup";

$(document).ready(() => {
  // Bootstrap tooltips
  if ($("[data-toggle='tooltip']").length) {
    $("[data-toggle='tooltip']").tooltip();
  }

  // ------------------------------------------------------- //
  //   Lightbox in galleries
  // ------------------------------------------------------ //

  $(".slider-gallery").each(function () {
    // the containers for all your galleries
    $(this).magnificPopup({
      delegate: "a", // the selector for gallery item
      type: "image",
      gallery: {
        enabled: true,
        tCounter: "", // markup of counter
      },
    });
  });

  $(".gallery").each(function () {
    // the containers for all your galleries
    $(this).magnificPopup({
      delegate: "a", // the selector for gallery item
      type: "image",
      gallery: {
        enabled: true,
      },
    });
  });
});
